import React, {useEffect, useState} from 'react'
import logo from "../assets/logo.png";
import tiktok from "../assets/tiktok.png";
import instagram from "../assets/instagram.png";
import {ReactComponent as AppStoreIcon} from '../assets/download-app-store.svg'

import './header.scss'
import SocialButton from "./social-button";

function Header() {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 667px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 667px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    return (
        <header className="App-header">
            <a href={"/"}>
                <img src={logo}
                     className="Logo-icon"
                     alt="logo"/>
            </a>
            <div className={"Social-btn-container"}>
                <SocialButton icon={instagram} title={"Instagram"} href={"https://instagram.com/visoirapp"}
                              style={{paddingLeft: 50}}/>
                <SocialButton icon={tiktok} title={"TikTok"} href={"https://vm.tiktok.com/ZTdxXhab2/"}/>
            </div>
            {matches && <div className={"App-store-icon"}>
                <a href={"https://apps.apple.com/us/app/visoir-photo-graphic-design/id1619517603"}><AppStoreIcon
                    style={{width: '100%', height: '100%'}}/></a>
            </div>}
        </header>
    );
}

export default Header