import '../Home-page.scss';
import {Grid} from "@mui/material";
import ipadIphone from '../assets/ipad-iphone.jpg'
import brandingBundles from '../assets/branding_bundles.jpg'
import bgRemove from '../assets/bg-remove.jpg'
import palettes from '../assets/palettes.jpg'

import FeatureRow from "../components/feature-row";
import React, {useEffect, useState} from "react";
import {ReactComponent as AppStoreIcon} from "../assets/download-app-store.svg";

function HomePage() {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 667px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 667px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);


    return (
        <div style={{justifyContent: 'center', display: 'flex', width: "100%"}}>
            <div style={{maxWidth: "1440px"}}>
                <Grid container spacing={10} columns={{xs: 12, sm: 12, md: 12}}>
                    <Grid container item justifyContent={"center"}>
                        <Grid container item direction={"row"} md={12} lg={10} justifyContent={'center'}>
                            <Grid item md={2} sm={1} xs={0}/>
                            <Grid item md={10} sm={8} xs={10}>
                                <div className={"headline-title"}>
                                    Meet Visoir - graphic design & photo editor. <span style={{color: "#8E8E93"}}>Unique visual identity for creators.</span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item md={8} sm={7} xs={10} lg={8}>
                            {!matches &&
                                <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
                                    <div style={{
                                        height: "48px",
                                        width: "144px",
                                        marginTop: "20px",
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center'
                                    }}>
                                        <a href={"https://apps.apple.com/us/app/visoir-photo-graphic-design/id1619517603"}><AppStoreIcon style={{width: '100%', height: '100%'}}/></a>
                                    </div>
                                </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item direction={"row"} justifyContent={"center"}>
                        <Grid item md={10} sm={10} xs={10} lg={10}>
                            <img className="headline-image" src={ipadIphone}/>
                        </Grid>
                    </Grid>
                    <div className={"spacer"}/>
                    <Grid container item justifyContent={"center"}>
                        <FeatureRow
                            image={brandingBundles}
                            title={"Explore branding bundles crafted by designers"}
                            subtitle={"Make your brand stand out on all platforms."}
                        />
                    </Grid>
                    <div className={"spacer"}/>
                    <Grid container item justifyContent={"center"}>
                        <FeatureRow
                            image={bgRemove}
                            title={"Instantly remove photo background"}
                            subtitle={"Get a smooth & clear cutout straight away."}
                            left
                        />
                    </Grid>
                    <div className={"spacer"}/>
                    <Grid container item justifyContent={"center"}>
                        <FeatureRow
                            image={palettes}
                            title={"Make templates uniquely yours"}
                            subtitle={"Match any template to your color palette."}
                        />
                    </Grid>
                    <div className={"spacer"}/>
                    <Grid container item justifyContent={"center"}>
                        <Grid container item direction={"row"} md={12} lg={10} justifyContent={'center'}>
                            <Grid item md={2} sm={1} xs={0}/>
                            <Grid item md={10} sm={8} xs={10}>
                                <div className="feature-list-title" style={{paddingBottom: '40px'}}>
                                    Get creative with pro-level editing Tools
                                </div>
                                <div className={"feature-list-item"}>Edit eveything</div>
                                <div className={"feature-list-item"}>Stand out from the crowd</div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={"spacer"}/>
                    <Grid container item justifyContent={"center"}>
                        <Grid container item direction={"row"} md={12} lg={10} justifyContent={'center'}>
                            <Grid item md={2} sm={1} xs={0}/>
                            <Grid item md={10} sm={8} xs={10}>
                                <div className="feature-list-title">
                                    Available on iPhone & iPad
                                </div>
                                {!matches &&  <div style={{
                                    height: "48px",
                                    width: "144px",
                                    marginTop: "20px",
                                    marginBottom: "60px",
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <a href={"https://apps.apple.com/us/app/visoir-photo-graphic-design/id1619517603"}><AppStoreIcon style={{width: '100%', height: '100%'}}/></a>
                                </div> || <div className={"spacer"}/>}

                                <div className={"feature-list-item"}>We would love to hear your <a
                                    href={"mailto:feedback@visoir.app"}>feedback@visoir.app</a>
                                </div>
                                <div className="feature-list-item">
                                    © 2022 UAB Hautecore Studios
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default HomePage;
