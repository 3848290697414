import '../Home-page.scss';
import {Grid, Typography} from "@mui/material";
import React from "react";

function PrivacyPage() {
    return (
        <Grid container spacing={10} columns={{xs: 12, sm: 12, md: 12}}>
            <Grid container item direction={"row"} justifyContent={"center"}>
                <Grid item md={8} sm={8} xs={10}>
                    <div style={{color: "#FFFFFF", textAlign: "left"}}>
                        <Typography variant="h4">
                            Privacy Policy
                        </Typography>
                        <br/>
                        <br/>

                        <Typography variant={"body1"}>
                            Effective date: May 26, 2022
                            <br/>
                            <br/>
                            Hautecore Studios UAB ("us", "we", or "our") operates the Visoir mobile application
                            (hereinafter referred to as the "Service").
                            <br/>
                            <br/>

                            This page informs you of our policies regarding the collection, use and disclosure of
                            personal
                            data
                            when you use our Service and the choices you have associated with that data.
                            <br/>
                            <br/>
                            We use your data to provide and improve the Service. By using the Service, you agree to the
                            collection and use of information in accordance with this policy. Unless otherwise defined
                            in
                            this
                            Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms
                            and
                            Conditions.
                        </Typography>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="h4">
                            Website Visitors
                        </Typography>
                        <br/>
                        <Typography variant={"body1"}>
                            Like most website operators, Visoir collects non-personally-identifying information of the
                            sort
                            that
                            web browsers and servers typically make available, such as the browser type, language
                            preference,
                            referring site, and the date and time of each visitor request. Visoir's purpose in
                            collecting
                            non-personally identifying information is to better understand how Visoir's visitors use its
                            website.
                            From time to time, Visoir may release non-personally-identifying information in the
                            aggregate,
                            e.g.,
                            by publishing a report on trends in the usage of its website.
                            <br/>
                            <br/>

                            Hautecore Studios also collects potentially personally-identifying information like Internet
                            Protocol
                            (IP) addresses for logged in users and for users leaving comments on
                            https://www.visoir.app
                            blog
                            posts. Visoir only discloses logged in user and commenter IP addresses under the same
                            circumstances
                            that it uses and discloses personally-identifying information as described below.
                        </Typography>
                        <br/>
                        <br/>
                        <br/>
                        <Typography variant="h5">
                            Definitions
                        </Typography>
                        <br/>
                        <Typography variant="h7">
                            Service
                        </Typography>
                        <br/>
                        <br/>
                        <Typography variant={"body1"}>
                            Service is the Visoir mobile application operated by Hautecore Studios UAB
                            <br/>
                            <br/>
                            <Typography variant="h7">
                                Personal Data
                            </Typography>
                            <br/>
                            <br/>
                            Personal Data means data about a living individual who can be identified from those data (or
                            from
                            those and other information either in our possession or likely to come into our possession).
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h7">
                                Usage Data
                            </Typography>
                            <br/>
                            <br/>
                            Usage Data is data collected automatically either generated by the use of the Service or
                            from
                            the
                            Service infrastructure itself (for example, the duration of a page visit).
                            <br/><br/><br/>
                            <Typography variant="h7">
                                Cookies
                            </Typography>
                            <br/><br/>
                            Cookies are small files stored on your device (computer or mobile device).
                            <br/><br/><br/>
                            <Typography variant="h7">
                                Data Controller
                            </Typography>
                            <br/><br/>
                            Data Controller means the natural or legal person who (either alone or jointly or in common
                            with
                            other persons) determines the purposes for which and the manner in which any personal
                            information
                            are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data
                            Controller
                            of
                            your Personal Data.
                            <br/><br/><br/>
                            <Typography variant="h7">
                                Data Processors (or Service Providers)
                            </Typography>
                            <br/><br/>
                            Data Processor (or Service Provider) means any natural or legal person who processes the
                            data on
                            behalf of the Data Controller. We may use the services of various Service Providers in order
                            to
                            process your data more effectively.
                            <br/><br/><br/>
                            <Typography variant="h7">
                                Data Subject (or User)
                            </Typography>
                            <br/>
                            Data Subject is any living individual who is using our Service and is the subject of
                            Personal
                            Data.
                        </Typography>
                        <br/>

                        <Typography variant="h5">
                            Information Collection and Use
                        </Typography>
                        <br/>
                        <Typography variant={"body1"}>
                            We collect several different types of information for various purposes to provide and
                            improve
                            our
                            Service to you.
                            <br/>
                            <br/><br/>
                            <Typography variant="h7">
                                Types of Data Collected
                            </Typography>
                            <br/>
                            <br/>
                            <Typography variant="h7">
                                Personal Data
                            </Typography>
                            <br/>
                            <br/>
                            While using our Service, we may ask you to provide us with certain personally identifiable
                            information that can be used to contact or identify you ("Personal Data"). Personally
                            identifiable
                            information may include, but is not limited to:
                            <ul>
                                <li>Email address</li>
                                <li>Cookies and Usage Data</li>
                            </ul>
                            We may use your Personal Data to contact you with newsletters, marketing or promotional
                            materials
                            and other information that may be of interest to you. You may opt out of receiving any, or
                            all,
                            of
                            these communications from us by following the unsubscribe link or instructions provided in
                            any
                            email
                            we send or by contacting us.
                            <br/><br/><br/>
                            <Typography variant="h7">
                                Usage Data
                            </Typography>
                            <br/>
                            <br/>
                            When you access the Service with a mobile device, we may collect certain information
                            automatically,
                            including, but not limited to, the type of mobile device you use, your mobile device unique
                            ID,
                            the
                            IP address of your mobile device, your mobile operating system, the type of mobile Internet
                            browser
                            you use, unique device identifiers and other diagnostic data ("Usage Data").

                            <br/>
                            <br/>
                            <br/>

                            <Typography variant="h7">
                                Tracking Cookies Data
                            </Typography>
                            <br/>
                            <br/>
                            We use cookies and similar tracking technologies to track the activity on our Service and we
                            hold
                            certain information.
                            <br/>
                            <br/>
                            Cookies are files with a small amount of data which may include an anonymous unique
                            identifier.
                            Cookies are sent to your browser from a website and stored on your device. Other tracking
                            technologies are also used such as beacons, tags and scripts to collect and track
                            information
                            and to
                            improve and analyse our Service.
                            <br/><br/>
                            You can instruct your browser to refuse all cookies or to indicate when a cookie is being
                            sent.
                            However, if you do not accept cookies, you may not be able to use some portions of our
                            Service.
                            <br/><br/>
                            Examples of Cookies we use:

                            <ul>
                                <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                                <li>Preference Cookies. We use Preference Cookies to remember your preferences and
                                    various
                                    settings.
                                </li>
                                <li>Security Cookies. We use Security Cookies for security purposes.</li>
                            </ul>
                        </Typography>
                        <br/>
                        <Typography variant={"body1"}>

                            <Typography variant="h5">
                                Use of Data
                            </Typography>

                            <br/>
                            Hautecore Studios UAB uses the collected data for various purposes:

                            <ul>
                                <li>To provide and maintain our Service</li>
                                <li>To notify you about changes to our Service</li>
                                <li> To allow you to participate in interactive features of our Service when you choose
                                    to
                                    do so
                                </li>
                                <li>To provide customer support</li>
                                <li>To gather analysis or valuable information so that we can improve our Service</li>
                                <li> To monitor the usage of our Service</li>
                                <li>To detect, prevent and address technical issues</li>
                                <li> To provide you with news, special offers and general information about other goods,
                                    services
                                    and
                                    events which we offer that are similar to those that you have already purchased or
                                    enquired
                                    about
                                    unless you have opted not to receive such information
                                </li>
                            </ul>
                            <br/>
                            <Typography variant="h5">
                                Legal Basis for Processing Personal Data under the General Data Protection Regulation
                                (GDPR)
                            </Typography>
                            <br/>
                            If you are from the European Economic Area (EEA), Hautecore Studios UAB legal basis for
                            collecting
                            and
                            using the personal information described in this Privacy Policy depends on the Personal Data
                            we
                            collect and the specific context in which we collect it.
                            <br/>
                            <br/>
                            Hautecore Studios UAB may process your Personal Data because:


                            <ul>
                                <li>We need to perform a contract with you</li>
                                <li>You have given us permission to do so</li>
                                <li>The processing is in our legitimate interests and it is not overridden by your
                                    rights
                                </li>
                                <li>For payment processing purposes</li>
                                <li>To comply with the law</li>
                            </ul>
                            <br/>
                            <Typography variant="h5">
                                Retention of Data
                            </Typography>
                            <br/>
                            Hautecore Studios UAB will retain your Personal Data only for as long as is necessary for
                            the
                            purposes
                            set out in this Privacy Policy. We will retain and use your Personal Data to the extent
                            necessary to
                            comply with our legal obligations (for example, if we are required to retain your data to
                            comply
                            with applicable laws), resolve disputes and enforce our legal agreements and policies.
                            <br/>
                            <br/>
                            Hautecore Studios UAB will also retain Usage Data for internal analysis purposes. Usage Data
                            is
                            generally retained for a shorter period of time, except when this data is used to strengthen
                            the
                            security or to improve the functionality of our Service, or we are legally obligated to
                            retain
                            this
                            data for longer periods.
                            <br/><br/>
                            <Typography variant="h5">
                                Transfer of Data
                            </Typography>
                            <br/>
                            Your information, including Personal Data, may be transferred to — and maintained on —
                            computers
                            located outside of your state, province, country or other governmental jurisdiction where
                            the
                            data
                            protection laws may differ from those of your jurisdiction.
                            <br/><br/>
                            If you are located outside Lithuania and choose to provide information to us, please note
                            that
                            we
                            transfer the data, including Personal Data, to Lithuania and process it there.
                            <br/><br/>
                            Your consent to this Privacy Policy followed by your submission of such information
                            represents
                            your
                            agreement to that transfer.
                            <br/><br/>
                            Hautecore Studios UAB will take all the steps reasonably necessary to ensure that your data
                            is
                            treated
                            securely and in accordance with this Privacy Policy and no transfer of your Personal Data
                            will
                            take
                            place to an organisation or a country unless there are adequate controls in place including
                            the
                            security of your data and other personal information.
                            <br/>
                            <br/>
                            <Typography variant="h5">
                                Disclosure of Data
                            </Typography>
                            <br/>

                            <Typography variant="h7">
                                Business Transaction
                            </Typography>
                            <br/>
                            <br/>
                            If Hautecore Studios UAB is involved in a merger, acquisition or asset sale, your Personal
                            Data
                            may be
                            transferred. We will provide notice before your Personal Data is transferred and becomes
                            subject
                            to
                            a different Privacy Policy.
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h7">
                                Legal Requirements
                            </Typography>
                            <br/>
                            <br/>
                            Hautecore Studios UAB may disclose your Personal Data in the good faith belief that such
                            action is
                            necessary to:
                            <ul>
                                <li>To comply with a legal obligation</li>
                                <li>To protect and defend the rights or property of Hautecore Studios UAB</li>
                                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                                <li>To protect the personal safety of users of the Service or the public</li>
                                <li>To protect against legal liability</li>
                            </ul>
                            <br/>
                            <Typography variant="h5">
                                Security of Data
                            </Typography>
                            <br/>
                            The security of your data is important to us but remember that no method of transmission
                            over
                            the
                            Internet or method of electronic storage is 100% secure. While we strive to use commercially
                            acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                            <br/><br/>

                            <Typography variant="h5">
                                Our Policy on "Do Not Track" Signals under the California Online Protection Act
                                (CalOPPA)
                            </Typography>
                            <br/>

                            We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web
                            browser
                            to inform websites that you do not want to be tracked.
                            <br/><br/>
                            You can enable or disable Do Not Track by visiting the Preferences or Settings page of your
                            web
                            browser.
                            <br/>
                            <br/>
                            <br/>
                            <Typography variant="h5">
                                Your Data Protection Rights under the General Data Protection Regulation (GDPR)
                            </Typography>
                            <br/>
                            If you are a resident of the European Economic Area (EEA), you have certain data protection
                            rights.
                            Hautecore Studios UAB aims to take reasonable steps to allow you to correct, amend, delete
                            or
                            limit
                            the use of your Personal Data.
                            <br/>
                            <br/>
                            If you wish to be informed about what Personal Data we hold about you and if you want it to
                            be
                            removed from our systems, please contact us.
                            <br/>
                            <br/>
                            In certain circumstances, you have the following data protection rights:
                            <ul>
                                <li>The right to access, update or delete the information we have on you. Whenever made
                                    possible,
                                    you
                                    can access, update or request deletion of your Personal Data directly within your
                                    account
                                    settings
                                    section. If you are unable to perform these actions yourself, please contact us to
                                    assist
                                    you.
                                </li>
                                <li>The right of rectification. You have the right to have your information rectified if
                                    that
                                    information is inaccurate or incomplete.
                                </li>
                                <li>The right to object. You have the right to object to our processing of your Personal
                                    Data.
                                </li>
                                <li>The right of restriction. You have the right to request that we restrict the
                                    processing of
                                    your
                                    personal information. The right to data portability. You have the right to be
                                    provided with
                                    a
                                    copy
                                    of the information we have on you in a structured, machine-readable and commonly
                                    used
                                    format.
                                </li>
                                <li>The right to withdraw consent. You also have the right to withdraw your consent at
                                    any time
                                    where
                                    Hautecore Studios UAB relied on your consent to process your personal information.
                                </li>
                            </ul>
                            <br/>
                            Please note that we may ask you to verify your identity before responding to such requests.
                            <br/>
                            <br/>
                            You have the right to complain to a Data Protection Authority about our collection and use
                            of
                            your
                            Personal Data. For more information, please contact your local data protection authority in
                            the
                            European Economic Area (EEA).
                            <br/>
                            <br/>

                            <Typography variant="h5">
                                Service Providers
                            </Typography>
                            <br/>
                            We may employ third party companies and individuals to facilitate our Service ("Service
                            Providers"),
                            provide the Service on our behalf, perform Service-related services or assist us in
                            analysing
                            how
                            our Service is used.
                            <br/>
                            <br/>
                            These third parties have access to your Personal Data only to perform these tasks on our
                            behalf
                            and
                            are obligated not to disclose or use it for any other purpose.
                            <br/>
                            <br/>
                            <Typography variant="h7">
                                Google Analytics
                            </Typography>
                            <br/>
                            <br/>
                            Google Analytics is provided by Alphabet Inc.
                            https://support.google.com/ads/answer/2662922?hl=en For more information on how Google uses
                            the collected information, please visit the "How Google uses data when you use our partners'
                            sites or app" page: http://www.google.com/policies/privacy/partners/ or visit the Privacy
                            Policy of Google: http://www.google.com/policies/privacy/
                            <br/>
                            <br/>
                            <Typography variant="h7">
                                Payments
                            </Typography>
                            <br/>
                            <br/>
                            We may provide paid products and/or services within the Service. In that case, we use
                            third-party
                            services for payment processing (e.g. payment processors).
                            <br/>
                            <br/>
                            We will not store or collect your payment card details. That information is provided
                            directly to
                            our
                            third-party payment processors whose use of your personal information is governed by their
                            Privacy
                            Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the
                            PCI
                            Security Standards Council, which is a joint effort of brands like Visa, MasterCard,
                            American
                            Express and Discover. PCI-DSS requirements help ensure the secure handling of payment
                            information.
                            <br/>
                            <br/>

                            The payment processors we work with are:

                            <ul>
                                <li>Apple Store In-App Payments Their Privacy Policy can be viewed at
                                    https://www.apple.com/legal/privacy/en-ww/
                                </li>
                            </ul>

                            <br/>
                            <Typography variant="h5">
                                Links to Other Sites
                            </Typography>
                            <br/>
                            Our Service may contain links to other sites that are not operated by us. If you click a
                            third
                            party
                            link, you will be directed to that third party's site. We strongly advise you to review the
                            Privacy
                            Policy of every site you visit.
                            <br/><br/>
                            We have no control over and assume no responsibility for the content, privacy policies or
                            practices
                            of any third party sites or services.
                            <br/><br/>

                            <Typography variant="h5">
                                Children's Privacy
                            </Typography>
                            <br/>

                            Our Service does not address anyone under the age of 18 ("Children").
                            <br/><br/>
                            We do not knowingly collect personally identifiable information from anyone under the age of
                            18.
                            If
                            you are a parent or guardian and you are aware that your Child has provided us with Personal
                            Data,
                            please contact us. If we become aware that we have collected Personal Data from children
                            without
                            verification of parental consent, we take steps to remove that information from our servers.
                            <br/><br/>
                            <Typography variant="h5">
                                Changes to This Privacy Policy
                            </Typography>
                            <br/>

                            We may update our Privacy Policy from time to time. We will notify you of any changes by
                            posting
                            the
                            new Privacy Policy on this page.
                            <br/><br/>
                            We will let you know via email and/or a prominent notice on our Service, prior to the change
                            becoming effective and update the "effective date" at the top of this Privacy Policy.
                            <br/><br/>
                            You are advised to review this Privacy Policy periodically for any changes. Changes to this
                            Privacy
                            Policy are effective when they are posted on this page.
                            <br/><br/>

                            <Typography variant="h5">
                                Contact Us
                            </Typography>
                            <br/>

                            If you have any questions about this Privacy Policy, please contact us:
                            <br/>
                            By email: hello@visoir.app
                        </Typography>
                    </div>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default PrivacyPage