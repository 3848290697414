import './social-button.scss'

function SocialButton({icon, title, onClick, href, ...rest}) {
    return (
        <div className="social-btn" onClick={onClick} {...rest}>
            <img style={{width: 20, height: 20, objectFit: 'contain'}} src={icon} className="Logo-icon" alt="logo"/>
            <a className="social-btn-title" href={href}>{title}</a>
        </div>
    );
}

export default SocialButton