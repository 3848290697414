import './App.css';
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import CookieConsent, {Cookies} from "react-cookie-consent";

import Header from './components/header'
import HomePage from "./routes/home-page";
import Footer from "./components/footer";
import PrivacyPage from "./routes/privacy";
import TermsPage from "./routes/terms";


function App() {
    return (
        <Router>
            <div className="App">
                <Header/>
                <Switch>
                    <Route exact path="/">
                        <HomePage/>
                    </Route>
                    <Route path="/privacy">
                        <PrivacyPage/>
                    </Route>
                    <Route path="/terms">
                        <TermsPage/>
                    </Route>
                </Switch>
                <Footer/>
                <CookieConsent
                    buttonText="Continue"
                    buttonStyle={{
                        backgroundColor: "#6CE997",
                        fontSize: "13px",
                        borderRadius: '4px',
                        fontFamily: '\'SF Pro Text\', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif'
                    }}
                >This website stores cookies on your browser. These cookies are used to collect
                    information about how you interact with our website and allow us to remember you. We use this
                    information in order to improve and customize your browsing experience and for analytics and metrics
                    about our visitors on this website. To find out more about the cookies we use, see our <a
                        style={{color: '#6CE997'}}
                        href={'/privacy'}>Privacy
                        Policy.</a>
                </CookieConsent>
            </div>
        </Router>
    );
}

export default App;
