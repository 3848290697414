import '../Home-page.scss';
import {Grid, Typography} from "@mui/material";
import React from "react";

function TermsPage() {
    return (
        <Grid container spacing={10} columns={{xs: 12, sm: 12, md: 12}}>
            <Grid container item direction={"row"} justifyContent={"center"}>
                <Grid item md={8} sm={8} xs={10}>
                    <div style={{color: "#FFFFFF", textAlign: "left"}}>
                        <Typography variant="body1">
                            <Typography variant="h4">
                                Terms Of Use. Last Updated May 26, 2022
                            </Typography>
                            <br/>
                            These terms of service govern your use of the Visoir software, licensed to you by UAB
                            Hautecore
                            Studios. You can decide to reject these terms of service, and that you do not agree to them.
                            However, if you do so then you shall have no right to use the software or services.
                            <br/><br/>
                            We develop a software for photo editing. When using the software, we’ll provide you access
                            to
                            content that we licensed from our partners, and allow you to search photos from all over the
                            web. You own all the rights for your works, but if you do not have a specific permission to
                            use
                            an image, we can’t provide you with that permission.
                            <br/><br/>
                            The entire contents displayed on the Application and all intellectual property rights to the
                            same are owned by us, our licensors, or both. Additionally, all trademarks, service marks,
                            trade
                            names and trade dress that may appear in our Services are owned by us, our licensors, or
                            identified third parties. If you import content from outside our collections (including via
                            our
                            web-search), you may do so, but you are required to receive the permission from the
                            copyright
                            holders. This means that we don’t claim any ownership of your images.
                            <br/><br/>
                            We allow you to share content you view through our application. However, this may be limited
                            at
                            any time.
                            <br/><br/>
                            Also, we may allow you to backup your photos, or to share them with your friends via other
                            social networks, all according to functionality of the services.
                            <br/><br/>

                            Please take note that when using our services some information may be processed by third
                            parties: photos may be processed by image processing services, statistical information may
                            be
                            collected by our service providers and technical information may be collected for improving
                            the
                            service.
                            <br/><br/>
                            Lastly, this service is made on an AS-IS basis; we can’t be held liable for any damage: not
                            if
                            your phone heats up and blows, not if your data is lost and not if your photos are published
                            on
                            the web publicly.

                            Please take the time to read the full terms of service, as they are the final and definite
                            agreement we have.
                            <br/><br/>

                            {/*<Typography variant="h5">*/}
                            {/*Sign-Up and Registration*/}
                            {/*</Typography>*/}
                            {/*Eligibility. When enrolling into the service, you represent and warrant that:*/}

                            {/*You are either over 18 years of age, or if you’re over 13 and under 18, you obtained your*/}
                            {/*legal*/}
                            {/*guardian’s consent to these terms;*/}
                            {/*You are the physical owner of your device. This means that you cannot use a cellphone you*/}
                            {/*received from the workplace in order to install this application without your employer’s*/}
                            {/*consent;*/}
                            {/*You are not a sex offender, nor have you been convicted with any violent crime or any crime*/}
                            {/*against your children.*/}
                            {/*There Is No Mandatory Signup. We do not coerce you into signing up for the service. We*/}
                            {/*encourage*/}
                            {/*it.*/}

                            {/*Signup. You may either sign up using your Facebook or Google account, where you provide us*/}
                            {/*with*/}
                            {/*basic permissions for you name, friend list and contact photo, or use your email address as*/}
                            {/*a*/}
                            {/*unique identifier. If you enroll with your email address, we shall send a message to your*/}
                            {/*email*/}
                            {/*address with a unique link to identify and verify your email address. We may also use your*/}
                            {/*email*/}
                            {/*address for sending newsletters, as we explain in the privacy policy. Following the*/}
                            {/*authentication, we’ll request a password and store it securely on our servers in a hashed*/}
                            {/*form.*/}

                            <Typography variant="h5">
                                Services
                            </Typography>
                            <br/>
                            We offer the following services, each of them is a “service” by itself, and all are
                            considered a
                            “service” under this agreement.

                            <ul>
                                <li>We may allow you to perform certain photo editing and manipulation services. These
                                    may be
                                    changed from time to time.
                                </li>
                                <li>We may allow you to publish your photos on the web, either using our platform, or if
                                    you
                                    decide,
                                    you can upload them to other services.
                                </li>
                                <li>We may allow you to interact with other users, either by following them, liking
                                    their works
                                    on
                                    commenting on their images. Please note that web and application features may
                                    differ.
                                </li>
                                <li>
                                    We may allow you to share your works with third parties, such as WhatsApp or other
                                    services.
                                    If
                                    you share content via these services, you are required to adhere to their terms of
                                    service.
                                </li>
                                <li>
                                    We can allow you to add different filters, import content from selected vendors, or
                                    search
                                    the
                                    web for content. When you use our web-search function, you acknowledge that we
                                    cannot
                                    inspect
                                    each image’s usage rights and licenses and therefore we cannot provide you with any
                                    guarantee
                                    that such image may be used and you assume all risks pertaining to such activity.
                                </li>
                                <li>
                                    We may allow you to add different icons from the Noun Project through our shapes
                                    function which are independently licensed under a Creative-Commons Share Alike, more
                                    information about it <a style={{
                                    color: "#6CE997"
                                }} href={"https://thenounproject.com/legal/"}>here</a>.
                                </li>
                                <li>We may also offer premium features for paid users. These may include advanced tools
                                    and
                                    access
                                    to more licensed content and graphic resources.
                                </li>
                                <li> We may allow you to backup your photos, either to your iCloud account or to another
                                    backup
                                    service. This service might be made available in part only for registered users, or
                                    only for
                                    paying users.
                                </li>
                            </ul>
                            Each of the services may be deprecated or removed at any time.
                            <br/><br/>
                            <Typography variant="h5">
                                Content Policy
                            </Typography>
                            <br/>
                            This content policy applies both to the content you submit through the service for us to
                            use,
                            share with other via the service or third parties, and for content others submit that you
                            may
                            use.

                            <ul>
                                <li>
                                    By either providing us with visual, textual, audiovisual, or other material or by
                                    using our
                                    service to share content, You hereby accept this content policy as set forth. You
                                    acknowledge
                                    that any violation of the content policy may cause termination of your service,
                                    removal of
                                    your
                                    content and legal action by both ourselves and the person whose rights were
                                    infringed.
                                </li>
                                <li>
                                    For the purpose of this policy, the term Content shall refer to images and/or text
                                    and/or
                                    videos
                                    used by yourself while creating content through the service and published through
                                    it.
                                </li>
                                <li>
                                    License. If you shared your Content via a third party service, then you grant each
                                    person
                                    you
                                    shared your Content with the right to send this Content to third parties, and to
                                    display,
                                    store
                                    or use this Content via such third party and according to its terms of service.
                                </li>
                                <li>
                                    Our License To You. We provide you, as our user, a limited, non-transferrable,
                                    non-sublicensable, license to use all the images and works made available via the
                                    services
                                    to
                                    create your own photos through the services. If you prepare digital or physical
                                    items
                                    containing
                                    our licensed content, then you may only use it for 600 units. This applies for
                                    digital
                                    downloads, prints, handouts and physical devices or copies. This means you can use
                                    our image
                                    banks, our libraries and our filters. However, you cannot reuse, resell or license
                                    to others
                                    our
                                    licensed content or otherwise convey it in a manner which does not reflect major
                                    alterations.
                                    This, for example, means that you can use the backgrounds we provide for photos
                                    under this
                                    limitation, but you cannot just copy these backgrounds and reuse them in a different
                                    application
                                    or sell them to a stock-photo website. If you plan to use our licensed content in
                                    more than
                                    600
                                    units, contact us and we will review the usage and may allow you to do so according
                                    to our
                                    discretion. The Software is bundled with fonts, licensed under the SIL Open Font
                                    License
                                    v1.1
                                    and Apache License, v2.0. A copy of these fonts is available upon request by email
                                    to
                                    hello@visoir.app .
                                </li>
                                <li>
                                    Offensive Content. You hereby warrant that your Content is not offensive and does
                                    not
                                    infringe
                                    the right of any third party, including, but not only:
                                    <ul>
                                        <li>
                                            Intellectual Property Infringing. Your content does not infringe any third
                                            party copyright,
                                            design, patent, trademark, trade secrets or any other intellectual property
                                            rights.
                                        </li>
                                        <li>
                                            Pornography. Your Content does not contain any text, image or depiction
                                            including extreme
                                            nudity, profanity, sexual activity, sexual innuendos, sexually offensive or
                                            otherwise
                                            obscene.
                                            We may allow artistic nudity and non-pornographic use of exposed bodies.
                                        </li>
                                        <li>
                                            Slanderous. Your Content does not slander, defame or otherwise harm the good
                                            name of others.
                                        </li>
                                        <li>
                                            Hate Speech and Racism. Your Content does not include any hate speech or
                                            racist opinions, as
                                            well as does not incite others to violent acts against persons solely based
                                            on their
                                            inclusion
                                            in a specific group.
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    Warranty. You hereby warrant that you are either a sole proprietor or a designated
                                    licensee
                                    of
                                    your Content and that no other party's rights are infringed or violated by your use
                                    of
                                    the
                                    content and the Service. You moreover warrant that no legal claim, dispute or
                                    lawsuit
                                    was
                                    filed
                                    against You or threatened against You for publishing content through the service.
                                </li>
                                <li>
                                    Notice & Takedown. In order to protect your rights, we have set up this Notice and
                                    Takedown
                                    Mechanism to help you protect your Content and to ensure that no unauthorized use is
                                    made.
                                </li>
                                <li>
                                    Harmless. You agree and warrant to hold us and our users harmless and to immediately
                                    indemnify
                                    us for any claim of copyright infringement, trademark dilution or patent
                                    infringement
                                    for
                                    any
                                    use of Content according to these Terms of Service. You also agree to indemnify us
                                    for
                                    any
                                    third
                                    party claim for copyright infringement due to their use of Content made available by
                                    You.
                                </li>
                                <li>
                                    Takedown. We value your right and others rights, and therefore shall comply with all
                                    state
                                    regulations regarding third party rights. Should you encounter any Content which you
                                    believe
                                    to
                                    be in violation of any of Your rights, good name or copyrights, Please file an
                                    infringement
                                    complaint to our Content officer at hello@visoir.app .
                                </li>
                                <li>
                                    We shall examine your complaint and shall forward it to the user who published said
                                    content
                                    for
                                    his answer, if possible.
                                </li>
                                <li>
                                    Should Your complaint be false, harassing or in order to prevent legal use of
                                    service,
                                    you
                                    shall
                                    bear all liability to compensate the user which you reported as infringing.
                                </li>
                                <li>
                                    In your complaint you will be required to provide us with:
                                    <ul>
                                        <li>
                                            A written statement regarding what content infringes your rights and proof
                                            that
                                            You hold
                                            those
                                            rights
                                        </li>
                                        <li>
                                            What is the exact item identifier and/or number
                                        </li>
                                        <li>
                                            Notification that you believe that the use made by the content is not
                                            considered
                                            fair-use,
                                            criticism, consumer protest or any other protected speech.
                                        </li>
                                    </ul>
                                </li>
                            </ul>


                            <Typography variant="h5">
                                Fees and Payment
                            </Typography>
                            <br/>
                            We charge our fees through your Apple account; Apple can offer refunds solely according to
                            its
                            policies. Our subscription is weekly, monthly or yearly recurring, and you may terminate it
                            at
                            any time. We may offer other payment options.
                            <br/><br/>
                            <Typography variant="h5">
                                Software License
                            </Typography>
                            <br/>
                            We provide you with a limited, non-sublicensable, temporary, license to use our software.
                            You
                            may not provide any person with a copy of our software, nor may you reverse-engineer,
                            decompile,
                            circumvent or otherwise attempt to make our software’s source code or features available.
                            You
                            may not attempt to obtain Premium functionality without a Premium account. We may revoke
                            your
                            license at any time and for any reason.
                            <br/><br/>
                            <Typography variant="h5">
                                Privacy Policy
                            </Typography>
                            <ul>
                                <li>You are not required under law to provide us with any information. However, if you
                                    do not
                                    provide us with such information we will not be able to provide you with the
                                    services.
                                </li>
                                <li>What Personally Identifiable Information Do We Retain? We collect some raw
                                    information which
                                    may
                                    be considered as personally identifiable, which is technical information about your
                                    device,
                                    such
                                    as your IP address, your operating system and other technical information. Moreover,
                                    when
                                    you
                                    register, we may retain the information you provided us at registration, including
                                    your
                                    name,
                                    photo and email address.
                                </li>
                                <li>
                                    How Do We Process This Information? We use the information to provide you with the
                                    services
                                    and
                                    to improve the services. These are the only two purposes which the information is
                                    used for.
                                </li>
                                <li>
                                    Who Are The Third Parties Who Have Access To This Information? We provide access to
                                    our
                                    employees, who are under strict confidentiality obligations, and to several third
                                    parties
                                    who
                                    provide us with services. We use the following third party services:
                                    <ul>
                                        <li>
                                            Google Analytics
                                        </li>
                                        <li>
                                            Sentry
                                        </li>
                                        <li>
                                            Heroku
                                        </li>
                                        <li>
                                            Amazon Web Services
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            We may also use the following services to process images or provide you parts of the
                            services:
                            <ul>
                                <li>Unsplash</li>
                                <li>Noun Project</li>
                            </ul>

                            <li>
                                Can We Contact You With Promotional Offers? Yes. We may use the information you provided
                                us,
                                including your email address to provide you with promotional offers and newsletters. We
                                may
                                also
                                contact you if we believe your account was compromised.
                            </li>
                            <li>
                                Can You Review or Remove Your Information? Yes. You can always review your information
                                using
                                our
                                software and panel. You can also request to delete all relevant information.
                            </li>
                            <li>
                                Compliance With Law Authorities. We comply with competent and authorized law authorities
                                and
                                shall provide them with information should we receive a valid request.
                            </li>
                            <br/><br/>
                            <Typography variant="h5">
                                No Warranty
                            </Typography>
                            <br/>
                            We supply the service on an “as-is” and “as-available” basis. Your use of the service is at
                            your
                            own risk and under your liability. We make no warranty that
                            The service will meet your requirements and
                            The service will be uninterrupted, timely, secure, or error-free and
                            The results that may be obtained from the use of the service will be accurate or reliable
                            and
                            The quality of any products, services, information, or other material purchased or obtained
                            by
                            You through Service will meet your expectations, or
                            Any errors in the Service will be corrected.
                            <br/><br/>
                            <Typography variant="h5">
                                Liability
                            </Typography>
                            <br/>
                            For no case and for no reason shall we be held liable for any damage, direct or indirect,
                            consequential, exemplary, physical or special, to you, any other user or any third party due
                            to
                            its misperformance of duties herein. We provide Service on an AS-IS basis and shall not be
                            held
                            liable, to the extent permitted by law, by any case of misconduct, negligence, gross
                            negligence,
                            malice or any other mean, to any damages or loss of property, including loss of your funds,
                            damages to virtual property, reputation and business reputation, user account information
                            including login information, loss of profit, loss of good name, all resulting from the use
                            or
                            inability to use services. Our liability for direct damages shall be limited to the fees
                            that
                            you actually paid us during the thirty days prior to the event causing the damage.
                            <br/><br/>
                            <Typography variant="h5">
                                Indemnity
                            </Typography>
                            <br/>
                            You hereby warrant and agree to hold us harmless and to indemnify us for any damage, loss,
                            expense, legal expense or cost incurred as a result of your use of the services in direct
                            violation of these terms of service, including any false representation.
                            <br/><br/>
                            <Typography variant="h5">
                                Support
                            </Typography>
                            <br/>
                            We provide limited support during regular business hours. Support is provided by email. We
                            may
                            use other support services from time to time. We cannot guarantee the response time for
                            support,
                            but we may provide service according to our own discretion, which might mean that paying
                            customers shall receive faster response times.
                            <br/><br/>
                            <Typography variant="h5">
                                Terminating User Accounts
                            </Typography>
                            <br/>
                            We shall have the right to terminate your use of the service or to terminate the service at
                            any
                            time and by providing a 30 day prior notice.
                            Moreover we may terminate your use of the service at any time and without prior written
                            notice
                            in any case where you breached these terms and such breach may cause us irreparable harm.
                            <br/><br/>
                            <Typography variant="h5">
                                Availability
                            </Typography>
                            <br/>
                            We might disable the service from time to time for scheduled backups, maintenance or
                            upgrades.
                            In some extreme cases, where urgent maintenance is required, we may disable the service
                            immediately and without notice.
                            <br/><br/>
                            <Typography variant="h5">
                                Amending These Terms
                            </Typography>
                            <br/>
                            We may amend these terms from time to time, provided that you shall be informed through
                            electronic communication on such amendment and shall be granted to option to terminate your
                            agreements with us by providing a 30 days prior written notice.
                            <br/><br/>
                            <Typography variant="h5">
                                Governing Laws, Jurisdiction, No Class Action
                            </Typography>
                            <br/>
                            The laws of the state of Lithuania shall exclusively govern these terms of service. Any
                            dispute
                            you may raise against us must be brought solely in the competent courts of the Vilnius
                            district.
                            You undertake to initiate only suits on your behalf and not to file any class action lawsuit
                            against us.
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TermsPage