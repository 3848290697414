import './footer.scss'
import {Grid} from "@mui/material";

function Footer() {
    return (
        <footer className="footer">
            <Grid container>
                <Grid item md={1} xs={1}/>
                <Grid item md={10} xs={10}>

                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer