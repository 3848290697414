import './feature-row.scss'
import React, {useState, useEffect} from 'react';
import {Grid} from "@mui/material";

function FeatureRow({image, title, subtitle, left = false}) {
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 900px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(max-width: 900px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);


    if (left) {
        return (
            <Grid container item justifyContent={"center"}>
                <Grid container item direction={"row"} md={12} lg={10} justifyContent={matches ? 'center' : 'flex-start'}>
                    <Grid item md={2} sm={0} xs={0}/>
                    <Grid item md={4} sm={8} xs={10} order={{sm: 2, xs: 2, md: 1}}>
                        <div className="feature-title">
                            {title}
                        </div>
                        <div className="feature-subtitle">
                            {subtitle}
                        </div>
                    </Grid>
                    <Grid item md={1} sm={1} xs={0} lg={1} order={{sm: 1, xs: 1, md: 2}}/>
                    <Grid item md={3} sm={12} xs={12} lg={3} order={{sm: 3, xs: 3, md: 3}}>
                        <img src={image} className="feature-image" alt="logo"/>
                    </Grid>
                </Grid>
            </Grid>
        )
    }


    return (
        <Grid container item justifyContent={"center"}>
            <Grid container item direction={"row"} md={12} lg={10} justifyContent={matches ? 'center' : 'flex-start'}>
                <Grid item md={2} sm={0} xs={0}/>
                <Grid item md={3} sm={12} xs={12} lg={3} order={{sm: 3, xs: 3, md: 1}}>
                    <img src={image} className="feature-image" alt="logo"/>
                </Grid>
                <Grid item md={1} sm={1} xs={0} lg={1} order={{sm: 1, xs: 1, md: 2}}/>
                <Grid item md={4} sm={8} xs={10} lg={4} order={{sm: 2, xs: 2, md: 3}}>
                    <div className="feature-title">
                        {title}
                    </div>
                    <div className="feature-subtitle">
                        {subtitle}
                    </div>
                </Grid>
            </Grid>
        </Grid>

    );
}

export default FeatureRow